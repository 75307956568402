// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// This ts-nocheck is my (ts) fault, the input type was incompatible, if you have an idea how to fix it, please do.

/*  eslint-disable no-nested-ternary */
import { css } from '@emotion/react';
import { SvgCamera } from '@web/components/svgs/misc';
import {
  listChildAnimation,
  theme,
  typography,
  variantProps,
} from '@web/features/theme';
import { motion } from 'framer-motion';
import * as React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { styles } from './form.styles';

/**
 * # isUniqueByName
 *
 * Take an array of items and ensure that the given item is unique by name
 *
 * @export
 * @param items List of items to sort
 * @param name name to look for
 * @return {boolean}
 */
export function isUniqueByName(items: Array<{ name: string }>, name: string) {
  return (
    items?.find(
      (reference) =>
        reference.name.toLowerCase().replace(' ', '_') ===
        name.toLowerCase().replace(' ', '_'),
    ) === undefined
  );
}

export type FormProps = {
  name: string;
  label: string;
  size?: 'auto' | 'small' | 'medium' | 'large' | 'full';
  config?: RegisterOptions;
  rightIcon?: React.ReactNode;
  onRightIconClick?: () => void;
};

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  FormProps;

export type TextAreaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> &
  FormProps;

/**
 * # Input
 *
 * Default input element
 *
 * @remarks
 * Must be used within FormProvider
 */
export function Input({
  size,
  label,
  type,
  config,
  name,
  rightIcon,
  onRightIconClick,
  ...rest
}: InputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  return (
    <motion.div
      css={styles.formField}
      className={` ${size} ${type} ${error?.message ? 'has-error' : 'idle'}`}
      variants={listChildAnimation}
      tabIndex=""
      {...variantProps}
    >
      <label htmlFor={name} css={styles.formLabel}>
        {label} <span css={styles.formError}>{error?.message}</span>
      </label>

      <input
        className="rr-ignore"
        id={name}
        type={type}
        css={styles.formInput}
        {...rest}
        {...register(name, config)}
      />

      {rightIcon && (
        <button
          type="button"
          css={styles.formRightIcon}
          onClick={onRightIconClick}
        >
          {rightIcon}
        </button>
      )}
    </motion.div>
  );
}

export function Textarea(props: TextAreaProps) {
  const { size, label, config, name, ...rest } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  return (
    <motion.div
      css={styles.formField}
      className={`${size} ${error?.message ? 'has-error' : 'idle'}`}
      variants={listChildAnimation}
      {...variantProps}
    >
      <label htmlFor={name} css={styles.formLabel}>
        {label} <span css={styles.formError}>{error?.message}</span>
      </label>

      <textarea
        id={name}
        cols={1}
        rows={4}
        wrap="hard"
        className="rr-ignore"
        css={[
          styles.formInput,
          css`
            resize: vertical;
            border-radius: ${theme.radii.xl};
          `,
        ]}
        {...rest}
        {...register(name, config)}
      />
    </motion.div>
  );
}

export type SelectProps<T> = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> &
  FormProps & {
    onChangeHook?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    options: Array<T> | null;
    getOptionKey: (item: T) => string | number;
    getOptionValue?: (item: T) => string | number;
  };

/**
 * # Select
 *
 * Default Select Element
 */
export function Select<T>({
  config,
  options,
  name,
  label,
  size,
  placeholder,
  defaultValue,
  getOptionKey,
  getOptionValue,
  onChangeHook,
  ...rest
}: SelectProps<T>) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { ref, onChange, ...registerProps } = register(name, config);

  const error = errors[name];

  return (
    <motion.div
      css={styles.formField}
      className={`${size} ${error?.message ? 'has-error' : 'idle'}`}
      variants={listChildAnimation}
      {...variantProps}
    >
      <label htmlFor={name} css={styles.formLabel}>
        {label} <span css={styles.formError}>{error?.message}</span>
      </label>

      <select
        className="rr-ignore"
        css={styles.formInput}
        id={name}
        {...rest}
        onChange={(e) => {
          onChange(e);
          onChangeHook?.(e);
        }}
        {...registerProps}
        ref={ref}
      >
        {defaultValue ? (
          <option value={defaultValue}>{defaultValue}</option>
        ) : placeholder ? (
          <option value="" disabled selected>
            {placeholder}
          </option>
        ) : null}

        {options?.map((item) => (
          <option key={getOptionKey(item)} value={getOptionKey(item)}>
            {getOptionValue ? getOptionValue(item) : getOptionKey(item)}
          </option>
        ))}
      </select>
    </motion.div>
  );
}

export type ImageUploadProps = InputProps & {
  multiple?: boolean;
  accept?: string;
  icon?: JSX.Element;
  placeholder?: string;
  maxSize?: number;
};

/**
 * # ImageUpload
 *
 * Used for uploading images
 *
 * @note
 * Used in a bunch of places whenever a background or {@link Avatar} is needed.
 *
 * We need custom access to the input's ref to trigger an image preview
 * @see https://react-hook-form.com/faqs#Howtosharerefusage
 */
export function ImageUpload({
  name,
  config,
  label,
  multiple,

  placeholder,
  size,
  icon = <SvgCamera />,
  maxSize = 5,

  ...rest
}: ImageUploadProps) {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  const error = errors[name];
  const fileSizeToMB = (filesize: number) => filesize / 1024 / 1024;

  const { ref, ...registerProp } = register(name, {
    ...config,
    validate: (fileList: FileList) => {
      const allFiles = Array.from(fileList);

      const firstFile = allFiles[0];
      const firstFileSize = firstFile && fileSizeToMB(firstFile.size);

      const validFiles = allFiles.every((file) => {
        return (
          file.type.includes('image') && fileSizeToMB(file.size) <= maxSize
        );
      });

      if (!config?.required && allFiles.length === 0) {
        return true;
      }

      if (fileList.length <= 0) {
        return 'Please select at least one image';
      }

      if (multiple && !validFiles) {
        return 'Please select valid images.';
      }

      if (firstFileSize > maxSize) {
        return `Please select an image less than ${maxSize}MB`;
      }

      return true;
    },
  });

  const [imageSrc, setImageSrc] = React.useState('');
  const [files, setFiles] = React.useState<FileList | null>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const addImagePreview = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { files: targetFiles } = target;

    const file: File | null = targetFiles && targetFiles[0];
    const fileSize = file && file.size / 1024 / 1024; // MB

    if (!fileSize || Math.ceil(fileSize) >= 5) return;

    const imageUrl = URL.createObjectURL(file);

    setImageSrc(imageUrl);
    setFiles(targetFiles);
  };

  return (
    <motion.div
      css={styles.imageUploadInput}
      className={`${size} ${error?.message ? 'has-error' : 'idle'}`}
      variants={listChildAnimation}
      {...variantProps}
    >
      <p css={styles.formLabel}>
        {label} <span css={styles.formError}>{error?.message}</span>
      </p>

      <label htmlFor={name} css={styles.formLabel}>
        {imageSrc ? (
          // these are user generated images
          // eslint-disable-next-line @next/next/no-img-element
          <img
            height="100%"
            width="100%"
            src={imageSrc}
            className="rr-block preview"
            alt="preview"
          />
        ) : (
          icon
        )}

        <span css={{ textAlign: 'center' }}>
          {files ? (
            <span>
              {files.length} file{files.length > 1 ? 's' : ''} selected
            </span>
          ) : (
            placeholder
          )}
        </span>

        {maxSize && !imageSrc ? (
          <span css={{ fontSize: typography.fontSizes.xs }}>
            Max: ({maxSize}MB)
          </span>
        ) : null}

        <input
          className="rr-ignore"
          type="file"
          id={name}
          {...(rest as typeof rest & {
            capture: boolean | 'user' | 'environment' | undefined;
          })} // fix for some weird typescript bug
          {...registerProp}
          onChange={(e) => {
            registerProp.onChange(e);
            addImagePreview(e);
          }}
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
        />
      </label>
    </motion.div>
  );
}
